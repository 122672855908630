import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, from, Observable, of as observableOf } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';

import { PrimaryInsuranceAndMembershipIsDpcGraphQLService } from '@app/appointment/primary-insurance-and-membership-is-dpc-graphql.service';
import { FeatureFlags, FeatureFlagVariantsWithOn } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';

import { User } from '../user';

export const InsuranceStatus = {
  SELF_PAY: 'Self Pay',
  PENDING: 'Pending',
  VERIFIED: 'Verified',
} as const;
export type InsuranceStatus = typeof InsuranceStatus[keyof typeof InsuranceStatus];

export interface InsuranceCaptureValues {
  variant: FeatureFlagVariantsWithOn;
  primaryInsuranceStatus: string | undefined;
}

export enum InsuranceCaptureSource {
  REVIEW_BOOKING,
  BOOKING_CONFIRMATION,
  APPOINTMENT_INVENTORY,
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceCaptureService {
  user$: Observable<User>;
  private _source: InsuranceCaptureSource;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private primaryInsuranceAndMembershipIsDpcGraphQLService: PrimaryInsuranceAndMembershipIsDpcGraphQLService,
    private userService: UserService,
    private membershipService: MembershipService,
    private router: Router,
    private links: LinksService,
  ) {
    this.user$ = this.userService.getUser();
  }

  get source(): InsuranceCaptureSource {
    return this._source;
  }

  set source(source: InsuranceCaptureSource) {
    this._source = source;
  }

  isSourceReviewBooking(): boolean {
    return this._source === InsuranceCaptureSource.REVIEW_BOOKING;
  }

  isSourceBookingConfirmation(): boolean {
    return this._source === InsuranceCaptureSource.BOOKING_CONFIRMATION;
  }

  isSourceAppointmentInventory(): boolean {
    return this._source === InsuranceCaptureSource.APPOINTMENT_INVENTORY;
  }

  updateLdUserAndGetInsuranceCaptureFlagEnabled$(isBillableVisit: boolean): Observable<boolean> {
    return this.updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit).pipe(
      map(
        result =>
          result.variant === FeatureFlagVariantsWithOn.ON_VARIANT || result.variant === FeatureFlagVariantsWithOn.ON,
      ),
    );
  }

  updateLdUserAndGetInsuranceCaptureValues$(isBillableVisit: boolean): Observable<InsuranceCaptureValues> {
    if (!isBillableVisit) {
      return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined });
    }

    return combineLatest([
      this.user$,
      this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch(),
      this.membershipService.getMembership(),
    ]).pipe(
      switchMap(([user, graphqlResult, membership]) => {
        const primaryInsuranceStatus = graphqlResult.data.patient?.primaryInsurance.verificationStatus;
        const isDpc = graphqlResult.data.membership?.isDpc ?? false;

        if (isDpc) {
          return observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus });
        }

        return from(
          this.launchDarklyService.updateUser({
            user,
            membership,
            customAttributes: { primaryInsuranceStatus },
          }),
        ).pipe(
          take(1),
          switchMap(() => this.insuranceCaptureFlagVariant$()),
          map(variant => ({ variant, primaryInsuranceStatus })),
        );
      }),
      catchError(() => observableOf({ variant: FeatureFlagVariantsWithOn.OFF, primaryInsuranceStatus: undefined })),
    );
  }

  insuranceCaptureTestModeFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$<boolean>(FeatureFlags.INSURANCE_CAPTURE_WEB_TEST_MODE, false);
  }

  insuranceCaptureFullPageFlagEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(map(result => result === FeatureFlagVariantsWithOn.ON_VARIANT || result === FeatureFlagVariantsWithOn.ON));
  }

  insuranceCaptureFlowAfterInventorySelectionEnabled$(): Observable<boolean> {
    return this.launchDarklyService
      .featureFlag$<FeatureFlagVariantsWithOn>(
        FeatureFlags.INSURANCE_CAPTURE_WEB_FULL_PAGE,
        FeatureFlagVariantsWithOn.OFF,
      )
      .pipe(
        switchMap(flagResult => {
          const flagEnabled =
            flagResult === FeatureFlagVariantsWithOn.ON_VARIANT || flagResult === FeatureFlagVariantsWithOn.ON;

          if (!flagEnabled) {
            return observableOf(false);
          }

          return this.primaryInsuranceAndMembershipIsDpcGraphQLService.fetch({}, { fetchPolicy: 'network-only' }).pipe(
            map(graphqlResult => {
              const primaryInsuranceStatus = graphqlResult.data?.patient?.primaryInsurance.verificationStatus;
              return primaryInsuranceStatus === InsuranceStatus.SELF_PAY;
            }),
          );
        }),
      );
  }

  setSourceAndNavigateToManageInsurance(source: InsuranceCaptureSource, replaceUrl = false): void {
    this.source = source;
    this.router.navigate([this.links.manageInsurance], { replaceUrl: replaceUrl });
  }

  handleNavigationAfterInventorySelection(
    insuranceCaptureFlowAfterInventorySelectionEnabled: boolean,
    isBillableVisit: boolean,
  ) {
    if (insuranceCaptureFlowAfterInventorySelectionEnabled && isBillableVisit) {
      this.setSourceAndNavigateToManageInsurance(InsuranceCaptureSource.APPOINTMENT_INVENTORY, true);
    } else {
      this.router.navigate([this.links.appointmentReview], { replaceUrl: true });
    }
  }

  private insuranceCaptureFlagVariant$(): Observable<FeatureFlagVariantsWithOn> {
    return this.launchDarklyService.featureFlag$<FeatureFlagVariantsWithOn>(
      FeatureFlags.INSURANCE_CAPTURE_WEB,
      FeatureFlagVariantsWithOn.OFF,
    );
  }
}
