import { Component, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { LinksService } from '@app/core/links.service';
import { EmailInputComponent, PasswordInputComponent } from '@app/shared';

import { RegistrationStepDirective } from '../../enterprise/registration-step.directive';

@Component({
  selector: 'om-create-account-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './create-account-step.component.html',
})
export class CreateAccountStepComponent extends RegistrationStepDirective {
  @Input() emailError: string;
  @Input() passwordError: string;
  @Input() trackResetPasswordClicked: () => Observable<any>;
  @Input() trackEmailAdminClicked: () => Observable<any>;
  @ViewChild('logInEmail', { static: true }) logInEmail: EmailInputComponent;
  @ViewChild('password', { static: true }) password: PasswordInputComponent;

  constructor(readonly links: LinksService) {
    super();
  }

  onSubmit() {
    this.submitting = true;
    this.logInEmail.markAsTouchedAndDirty();
    this.password.markAsTouchedAndDirty();
    this.submit.emit();
  }

  onError() {
    this.submitting = false;
  }
}
